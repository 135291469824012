import { Environment, TokenConfig } from "@/model/interfaces";

export const EMAIL_APP_LINK = "https://mail.google.com/mail/u/0/#inbox";

export const ADVERT_MEDIA = [
  {
    label: "Twitter",
    value: "Twitter"
  },
  {
    label: "Whatsapp",
    value: "Whatsapp"
  },
  {
    label: "Facebook",
    value: "Facebook"
  },
  {
    label: "LinkedIn",
    value: "LinkedIn"
  },
  {
    label: "Instagram",
    value: "Instagram"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const EMPLOYMENT_TYPE = [
  {
    label: "Full Time",
    value: "full_time"
  },
  {
    label: "Part Time",
    value: "part_time"
  },
  {
    label: "Contract",
    value: "contract"
  },
  {
    label: "Internship",
    value: "internship"
  },
  {
    label: "Volunteer",
    value: "volunteer"
  },
  {
    label: "Others",
    value: "others"
  }
];

export const CURRENCY = [
  {
    label: "NGN",
    value: "NGN"
  },
  {
    label: "USD",
    value: "USD"
  },
  {
    label: "USDC",
    value: "USDC"
  },
  {
    label: "USDT",
    value: "USDT"
  },
  {
    label: "BUSD",
    value: "BUSD"
  },
  {
    label: "DAI",
    value: "DAI"
  }
  // {
  //   label: "EUR",
  //   value: "EUR"
  // }
  // {
  //   label: "GBP",
  //   value: "GBP"
  // },
  // {
  //   label: "AED",
  //   value: "AED",
  //   code: "ae"
  // }
  // {
  //   label: "GHS",
  //   value: "GHS",
  // },
  // {
  //   label: "KES",
  //   value: "KES",
  // },
];

export const FUNDING_CURRENCIES = [
  {
    label: "NGN",
    value: "NGN",
    code: "ng"
  },
  {
    label: "USD",
    value: "USD",
    code: "us"
  }
];

export const MINIMUM_FUNDING_AMOUNT = {
  NGN: 10000,
  USD: 25,
  EUR: 20,
  GBP: 15,
  AED: 90,
  GHS: 100,
  KES: 2500
};

export const MINIMUM_WITHDRAWAL_AMOUNT = {
  Fiat: 5,
  Crypto: 1
};

export const WEEK_DAYS = [
  {
    label: "Monday",
    value: 0
  },
  {
    label: "Tuesday",
    value: 1
  },
  {
    label: "Wednesday",
    value: 2
  },
  {
    label: "Thursday",
    value: 3
  },
  {
    label: "Friday",
    value: 4
  },
  {
    label: "Saturday",
    value: 5
  },
  {
    label: "Sunday",
    value: 6
  }
];

export const PAY_FREQUENCY_OPTIONS = [
  {
    label: "Weekly",
    value: "Weekly"
  },
  {
    label: "Every other week",
    value: "Every other week"
  },
  {
    label: "Monthly",
    value: "Monthly"
  },
  {
    label: "Twice per month",
    value: "Twice per month"
  }
];

export const CURRENCY_SYMBOL = {
  NGN: "₦",
  USD: "$",
  EUR: "€",
  GBP: "£",
  AED: "د.إ",
  GHS: "₵",
  KES: "Ksh",
  USDC: "USDC",
  USDT: "USDT",
  BUSD: "BUSD",
  DAI: "DAI"
};

export const currentEnvironment = (process.env.NODE_ENV ||
  "development") as Environment;

// Token configuration
export const TOKEN_KEY: TokenConfig = {
  development: {
    webapp: "dev_webapp_token",
    admin: "dev_admin_token"
  },
  production: {
    webapp: "prod_webapp_token",
    admin: "prod_admin_token"
  }
};
