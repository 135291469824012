import { deleteCookie, getCookie, setCookie } from "cookies-next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { loginUser } from "../../../../api/auth";
import { currentEnvironment, TOKEN_KEY } from "@/src/lib/utils/constants";
import { formatApiErrors } from "@/src/lib/utils/helpers";
import BaseInput from "../../ui/BaseInput";
import AuthLayout from "../../../../layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import useCustomHookForm from "../../../../hooks/useCustomHookForm";
import { useFormUtil } from "../../../../hooks/useFormUtil";
import { loginSchema } from "../../../schema";

export default function LoginUser() {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useCustomHookForm(loginSchema);
  const { defaultErrors, setDefaultErrors } = useFormUtil(loginSchema.fields);

  const handleRedirect = React.useCallback(
    (userData: any, nextUrl?: string, invoiceReference?: string) => {
      const isBusiness = userData?.account_type === "Business";
      const baseRoute = `/${isBusiness ? "business" : "talent"}`;

      if (invoiceReference) {
        return `${baseRoute}/dashboard/invoices/received?reference=${invoiceReference}`;
      }

      if (nextUrl) {
        const decodedUrl = decodeURIComponent(nextUrl);
        return isBusiness
          ? decodedUrl.replace("/talent", "/business")
          : decodedUrl.replace("/business", "/talent");
      }

      return `${baseRoute}/dashboard/${
        userData?.kyc_status === "Not submitted" ? "compliance" : ""
      }`;
    },
    []
  );

  const handleAuthSuccess = React.useCallback(
    (userData: any) => {
      // Set essential cookies and local storage
      setCookie(TOKEN_KEY[currentEnvironment].webapp, userData?.token);
      setCookie("account_type", userData?.account_type);

      // Handle redirects
      const nextUrl: string = getCookie("nextUrl") as string;
      const invoiceReference: string = getCookie("invoice_reference") as string;

      // Clean up cookies
      deleteCookie("email");
      nextUrl && deleteCookie("nextUrl");
      invoiceReference && deleteCookie("invoice_reference");

      // Redirect user
      const redirectPath = handleRedirect(userData, nextUrl, invoiceReference);
      router.push(redirectPath);
      router.reload();
    },
    [router, handleRedirect]
  );

  const { mutate, isPending } = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      const userData = data?.data;
      handleAuthSuccess(userData);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage?.includes("not verified")) {
        setCookie("email", getValues("email"));
        router.push("/auth/verify-email");
      }
      setDefaultErrors(formatApiErrors(error?.response?.data?.errors));
    }
  });

  const handleLogin = React.useCallback(
    (data: any) => {
      mutate(data);
    },
    [mutate]
  );

  return (
    <AuthLayout>
      <div className="login-user">
        <form className="login-user__form" onSubmit={handleSubmit(handleLogin)}>
          <h3>Login to your account</h3>

          <BaseInput
            label="Email"
            placeholder="Enter Email"
            id="email"
            type="email"
            autoComplete="email"
            register={register}
            errors={errors}
            defaultErrors={defaultErrors["email"]}
          />
          <BaseInput
            label="Password"
            placeholder="Enter Password"
            id="password"
            type="password"
            autoComplete="current-password"
            register={register}
            errors={errors}
            defaultErrors={defaultErrors["password"]}
          />

          <Link href="/auth/forgot-password" passHref>
            <a className="base-btn--link login-user__form__forgot-password">
              Forgot Password
            </a>
          </Link>

          <button
            className="base-btn--primary login-user__form__login-button"
            data-loading={isPending}
          >
            Login
          </button>
        </form>

        <p className="login-user__no-account-yet">
          Do not have an account yet?{" "}
          <Link href="/onboard">
            <a className="base-btn--link">Sign up</a>
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
}
